import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from '@/router/axios'
import Vant from 'vant';
import 'vant/lib/index.css';
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import {pinyin} from 'pinyin-pro';
import 'amfe-flexible'


//视频播放组件
import {videoPlayer} from "vue-video-player";
import "video.js/dist/video-js.css";

import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
Vue.use(vueMiniPlayer)


import VueCoreVideoPlayer from 'vue-core-video-player'
Vue.use(VueCoreVideoPlayer)


// 全局组件挂载
Vue.component('videoPlayer', videoPlayer)

import '@/style/styles/element-variables.scss'
import '@/style/styles/index.scss' // global css
import '@/style/styles/bkx.scss' // bkx css
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import tim from "./utils/TIM";
import TIM from 'tim-js-sdk';
import md5 from 'md5'
import VConsole from 'vconsole';
Vue.prototype.$tim = tim;
Vue.prototype.$TIM = TIM;

const requireComponent = require.context(
    './components', // 其组件目录的相对路径
    true, // 是否查询其子目录 如果有子目录，将其改为true，如果没有，将其改为false
    /.(vue|js)$/
)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
Viewer.setDefaults({
    toolbar: false
})

// const isPc = () => {
//     const userAgentInfo = navigator.userAgent;
//     const Agents = ["Android", "iPhone",
//         "SymbianOS", "Windows Phone",
//         "iPad", "iPod"];
//     let flag = true;
//     for (let v = 0; v < Agents.length; v++) {
//         if (userAgentInfo.indexOf(Agents[v]) > 0) {
//             flag = false;
//             break;
//         }
//     }
//     return flag;
// }
// if (process.env.NODE_ENV != "prod" && !isPc()) {
//     const vConsole = new VConsole();
// }

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    const componentName = upperFirst(
        camelCase(
            fileName.split('/').pop().replace(/\.\w+$/, '')
        )
    )
    Vue.component(componentName, componentConfig.default || componentConfig)
})

router.beforeEach((to, from, next) => {
    if (to.name != 'login' && to.name != 'scan_xiang' && to.path != '/weixin') {
        if (!localStorage.getItem('token')) {
            next('/login')
        } else {
            next()
        }
    } else {
        next()
    }
})
Vue.use(Vant);
Vue.use(ElementUI);
Vue.prototype.judgment_id = 1 // 值为1时隐藏部分功能 为2时打开部分功能
Vue.prototype.$axios = axios
Vue.prototype.$md5 = md5
Vue.prototype.link = window.location.href.split('#')[0] == 'https://juxin.kunzhousc.com/' ? 'https://renhe.kunzhousc.com/#/' : 'https://test.renhe.kunzhousc.com/#/'
Vue.prototype.$user_info = JSON.parse(localStorage.getItem('user_info'))
Vue.prototype.cun_user = function (data) {
    Vue.prototype.$user_info = data ? data : JSON.parse(localStorage.getItem('user_info'))
}
/**
 * 获取地址经纬度
 * */
Vue.prototype.getLocation = function () {
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 60000
    };

    function successCallback(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        alert(`获取到经纬度坐标：${latitude}, ${longitude}`);
    }

    function errorCallback(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                alert("用户拒绝位置服务请求");
                break;
            case error.POSITION_UNAVAILABLE:
                alert("位置信息不可用");
                break;
            case error.TIMEOUT:
                alert("获取位置信息超时");
                break;
            default:
                alert("获取位置信息失败");
        }
    }

    if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
    } else {
        alert("浏览器不支持获取地理位置信息");
    }
}
/* 部分隐藏处理
 ** str 需要处理的字符串
 ** frontLen 保留的前几位
 ** endLen 保留的后几位
 ** cha 替换的字符串
 */
Vue.prototype.plusXing = function (str, frontLen, endLen, cha, id) {
    if (str.length == 2) {
        return `${str[0]}*`
    } else {
        let len = str.length - frontLen - endLen;
        if (len > 5) {
            len = 5
        }
        if (id) {
            len = 4
        }
        var xing = "";
        for (let i = 0; i < len; i++) {
            xing += cha;
        }
    }
    return (
        str.substring(0, frontLen) + xing + str.substring(str.length - endLen)
    );
}

Vue.prototype.timestampToTime = function (timestamp) {
    // 时间戳为10位需*1000，时间戳为13位不需乘1000
    var date = new Date(timestamp * 1000);
    var Y = date.getFullYear() + "-";
    var M =
        (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
    var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    var h = (date.getHours()<10?"0"+date.getHours():date.getHours()) + ":";
    console.log(h,'H');
    var m = (date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes()) + ":";
    var s = date.getSeconds()<10?"0"+date.getSeconds():date.getSeconds();
    return Y + M + D + h + m + s;
}
// 刷新用户存储信息
Vue.prototype.new_user_info = function (userId, utype, url) {
    Vue.prototype.$axios.post('/auth/juxin-app/refreshToken', {
        userId: userId, utype: utype
    }).then(res => {
        if (res.code == 200) {
            localStorage.setItem('token', 'JuxinApp ' + res.data.access_token)
            setTimeout(() => {
                Vue.prototype.$axios({
                    url: 'bkx-juxin-app/common/getInfoByToken',
                    method: 'GET',
                }).then(res => {
                    if (res.code == 200) {
                        localStorage.setItem('user_info', JSON.stringify(res.data))
                        Vue.prototype.cun_user(res.data)
                        if (url) {
                            router.push(url)
                        }
                    } else {
                        Vue.prototype.$toast(res.msg)
                    }
                })
            })
        }
    })
}
Vue.prototype.pulish_xiao = function (fromAccount, toAccount) {
    this.$axios.post('/bkx-juxin-app/dialogue/notificationDialogue', {
        fromAccount: fromAccount,
        toAccount: toAccount
    }).then()
}
Vue.prototype.group_xiao = function (groupId) {
    this.$axios.post('/bkx-juxin-app/dialogue/notificationDialogue', {
        groupId: groupId
    }).then()
}
// 数据处理成金额
Vue.prototype.decimal_two = function (money, id) {
    var ret_money = ''
    var new_noney = JSON.stringify(money)
    if (new_noney.indexOf('.') != -1) {
        var amoney = JSON.parse(new_noney).split('.')
        if (amoney[1].length == 1 && !id) {
            ret_money = `${amoney[0]}.${amoney[1]}0`
        } else if (amoney[1].length >= 2) {
            ret_money = `${amoney[0]}.${amoney[1][0]}${amoney[1][1]}`
        } else {
            ret_money = JSON.parse(new_noney)
        }
    } else if (money == '0') {
        return money
    } else if (!id) {
        ret_money = `${JSON.parse(new_noney)}.00`
    } else {
        ret_money = money
    }
    return ret_money
}
/**
 * 腾讯云登录
 * */
import genTestUserSig from "@/utils/GenerateTestUserSig";

Vue.prototype.deng_tim = function (userID) {
    let userSig = genTestUserSig(userID) // 获取userSig签名
    let promise = tim.login({userID: userID, userSig: userSig.userSig});
    promise.then(function (imResponse) {
        console.log(imResponse.data); // 登录成功
        if (imResponse.data.repeatLogin === true) {
            console.log(imResponse.data.errorInfo);
        }
    }).catch(function (imError) {
        console.warn('login error:', imError); // 登录失败的相关信息
    });
}
/**
 * 退出登录
 * */
Vue.prototype.tui_tim = function () {
    let promise = tim.logout();
    promise.then(function (imResponse) {
        console.log(imResponse.data); // 登出成功
    }).catch(function (imError) {
        console.warn('logout error:', imError);
    });
}
/**
 * 清除聊天记录
 * */
Vue.prototype.delexiao = function (uuid) {
    Vue.prototype.$axios.put('juxin-app/dialogue/updateIsRead', {
        uuid: uuid
    })
}
/**
 * 处理通讯录数据
 * 1、获取数据列表 解析出首字母都有那些组成数组
 * 2、处理特殊符号 push到首字母列表中的最后一位
 * 3、生成新数组判断数据列表中首字母和首字母列表中的是否匹配 然后添加到新数组中对应的位置
 * 4、将未匹配的首字母为特殊符号的下标获取，然后去重形成下标的新数组
 * 5、循环下标数组再从数据列表中获取特殊符号的数据push到新数组的最后一位
 * 使用方法
 *         this.area_list = this.processing_area(data)  ['A','J','G']
 *         this.prop_list = this.processing_book(data, this.area_list)
 * */
Vue.prototype.$book_list = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '#']
Vue.prototype.processing_book = function (list, new_arr) {
    let new_list = []
    for (let q = 0; q < new_arr.length; q++) {
        for (let i = 0; i < list.length; i++) {
            if (list[i].nickName != null) {
                if (new_arr[q] == pinyin(list[i].remark ? list[i].remark : list[i].nickName, {toneType: 'none'})[0].toUpperCase()) {
                    new_list.push(new_arr[q])
                }
            }
        }
    }
    new_list = [...new Set(new_list)]
    let news_list = []
    let old_list = []
    for (let j = 0; j < new_list.length; j++) {
        news_list.push({name: new_list[j]})
    }
    if (new_list.length == 0 && list.length != 0) {
        news_list = [{
            name: '#',
            list: []
        }]
        news_list[0].list.push(...list)
    } else {
        for (let q = 0; q < news_list.length; q++) {
            news_list[q].list = []
            for (let i = 0; i < list.length; i++) {
                var pattern = /[A-Za-z]+/
                if (list[i].nickName != null) {
                    if (news_list[q].name == pinyin(list[i].remark ? list[i].remark : list[i].nickName, {toneType: 'none'})[0].toUpperCase()) {
                        news_list[q].list.push(list[i])
                    } else if (pattern.test(pinyin(list[i].remark ? list[i].remark : list[i].nickName, {toneType: 'none'})[0]) == false) {
                        old_list.push(i)
                    }
                }
            }
        }
        if (old_list.length != 0) {
            news_list.push({name: '#'})
            old_list = [...new Set(old_list)]
            news_list[news_list.length - 1].list = []
            for (let g = 0; g < old_list.length; g++) {
                news_list[news_list.length - 1].list.push(list[old_list[g]])
            }
        }
    }
    return news_list
}
/**
 * 修改个人信息
 * */
Vue.prototype.xiu_info = function (id, data) {
    console.log(data, 'data')
    let info = JSON.parse(localStorage.getItem('user_info'))
    Vue.prototype.$axios.put('/bkx-base-user/juxinUserInfo/updateUserInfoByToken', {
        avatar: id == 0 ? data : info.avatar, // 账号头像
        account: info.account, // 聚信号
        sex: id == 1 ? data : info.userInfo.sex, // 账号性别
        nickName: id == 2 ? data : info.nickName, // 账号名称
        provinceName: id == 3 ? data[0] : info.userInfo.provinceName, // 账号省名
        province: id == 3 ? data[0].code : info.userInfo.province, // 账号省编码
        city: id == 3 ? data[1].code : info.userInfo.city, // 账号市编码
        cityName: id == 3 ? data[1] : info.userInfo.cityName, // 账号市名
        signature: id == 5 ? data : info.userInfo.signature, // 账号个性签名
        isLookAddressBook: id == 7 ? data : info.userInfo.isLookAddressBook, //手机号
        isReceiveStranger: id == 8 ? data : info.userInfo.isReceiveStranger,//接收陌生人消息
        isRecommendUsen: id == 9 ? data : info.userInfo.isRecommendUsen, //
        isRecommendMe: id == 10 ? data : info.userInfo.isRecommendMe,//推荐给其他用户
        isRecomendation: id == 11 ? data : info.userInfo.isRecomendation,
        isRecommendSystemUpdates: id == 12 ? data : info.userInfo.isRecommendSystemUpdates,//推送系统更新
        isRecommendshowTogether: id == 13 ? data : info.userInfo.isRecommendshowTogether, //推送一起秀
        isRecommendUser: id == 14 ? data : info.userInfo.isRecommendUser, //允许推荐好友
        isRecommendation: id == 15 ? data : info.userInfo.isRecommendation,
        isRecommendGroup: id == 16 ? data : info.userInfo.isRecommendGroup, //推荐群聊
        isRecommendShowTogether: id == 17 ? data : info.userInfo.isRecommendShowTogether,
    }).then(res => {
        console.log(res);
        if (res.code == 200) {
            Vue.prototype.new_user_info(info.uuid, this.$user_info.roleType)

        } else {
            Vue.prototype.$toast(res.msg)
        }
    })

}
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
