<template>
    <div>
        <!-- 头部标题 -->
        <div class="system_home_top">
            <img src="https://file.kunzhousc.com/shop/uniApp/back.png" @click="goback">
            <div style="font-size: 16px">用户激活</div>
            <div style="width: 18px;"></div>
        </div>
        <div class="price_back">
            账户积分：{{integral}}
        </div>
        <div class="jifen">
            <span>{{ price }}</span>
            <span style="font-size: 14px;margin-left: 5px;">积分</span>
        </div>
        <div class="title">您需要支付{{ price }}个积分，即可完成激活</div>
        <div class="buttom" @click="zhifu" style="font-size: 16px">立即支付</div>
        <!-- 键盘支付 -->
        <password_page :showKeyboard="showKeyboard" :price="price" title="支付积分" :id="id"
                       :new_pass="new_pass" but_no="1" @payment="payment"></password_page>
    </div>
</template>

<script>
import Password_page from "@/components/password/password_page.vue";

export default {
    components: {Password_page},
    data() {
        return {
            showKeyboard: false, // 密码输入框显隐
            id: 3, // 荷包类型 1 推广奖励 2 代金券 3 积分
            price: '',
            new_pass: '', //清除密码
            integral: ''
        }
    },
    mounted() {
        this.$axios({
            url: '/shop-system/prepaymentAccount/selectUserInitInfo',
            method: "GET",
            params: {
                userId: this.$user_info.uuid,
                account: this.$user_info.account
            }
        }).then(res => {
            if (res.code == 200) {
                this.price = res.data.money
            } else {
                this.$toast(res.msg)
            }
        })
        let accountType = this.$user_info.account.toString()
        accountType = accountType.slice(0, 3)
        this.$axios({
            url: 'bkx-pay/commonwallet/getWalletBalance',
            methods: 'GET',
            params: {
                userId: this.$user_info.account,
                accountType: accountType
            }
        }).then(res => {
            console.log(res)
            if (res.code == 200) {
                this.integral = (res.data.obj.integral / 100).toFixed(2)
            }
        })
    },
    methods: {
        /**
         * 返回我的页面
         * */
        goback() {
            this.$router.push('/')
        },
        /**
         * 支付积分激活
         * */
        zhifu() {
            if (this.price > this.integral) {
                this.$toast('您的积分余额不足，可以通过购买商品获得积分')
            } else {
                if (this.$user_info.userInfo.payPasswordStatus == 1) {
                    this.showKeyboard = true
                } else {
                    this.$router.push('/Give_shezhi?id=1')
                }
            }
        },
        /**
         * 支付
         * */
        payment(data) {
            this.$axios({
                url: '/bkx-pay/commonwallet/userPayJihuo',
                method: "GET",
                params: {
                    account: this.$user_info.account,
                    money: this.price * 100,
                    password: this.$md5(data)
                }
            }).then(res => {
                if (res.code == 200) {
                    this.$router.push('/')
                } else {
                    //报错后调用以下代码，清除密码
                    // this.$toast(res.msg)
                    this.new_pass = 1
                    setTimeout(() => {
                        this.new_pass = ''
                    }, 500)
                }
            })
        }
    }
}
</script>

<style>
.system_home_top {
    height: 88px;
    line-height: 88px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.system_home_top img {
    width: 36px;
    height: 36px;
}

.price_back {
    height: 100px;
    line-height: 100px;
    font-size: 28px;
    padding: 0 0 0 20px;
    background-color: #FFF9F5;
}

.jifen {
    margin: 20px;
    line-height: 88px;
    padding: 0 20px;
    border-bottom: 2px solid #EEEEEE;
    display: flex;
    align-items: center;
}

.jifen span {
    font-size: 56px;
}

.title {
    margin: 20px 20px 20px 40px;
    color: #666;
    font-size: 28px;
}

.buttom {
    width: 360px;
    height: 88px;
    line-height: 88px;
    text-align: center;
    border-radius: 8px;
    background-color: #FC9013;
    color: #fff;
    margin: 0 auto;
    margin-top: 600px;
}
</style>
